import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Abril"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/abril"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/abril",
        title: "Efemérides - Abril | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – ABRIL</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de abril de 1880:&nbsp;Falleció en la ciudad de Rosario, Aarón
      Castellanos. Nació en Salta el 8 de agosto de 1800. Se enroló en las
      huestes del general Güemes, luchando contra los realistas hasta alcanzar
      el grado de teniente en el famoso escuadrón “Los Infernales”.
    </p>
    <p>
      1 de abril de 1886:&nbsp;Nació en Salta, Alberto Alvarez Tamayo, abogado
      que se destacó en su profesión y como docente en el Colegio Nacional de
      Salta.
    </p>
    <p>
      1 de abril de 1946:&nbsp;Nació Mario Ernesto Peña, periodista y publicista
      que conduce su programa «Compartiendo su mañana», en Aries FM Estéreo, que
      dirige y es de su propiedad.
    </p>
    <p>1 de abril de 1966: Inició su transmisión Canal 11 de Salta.</p>
    <p>
      1 de abril de 1967: Llegó a Salta la congregación religiosa de las
      Hermanas Misioneras de Cristo Rey. Venían con el objeto de atender una
      Guardería de Niños y un Jardín de Infantes en la Parroquia de Nuestra
      Señora del Pilar. La guardería se inauguró el 27 de mayo del mismo año, el
      jardín de infantes un tiempo después.
    </p>
    <p>
      1 de abril de 1971:&nbsp;Se inauguró el Profesorado a nivel elemental en
      el Colegio Santa Rosa de Viterbo en la ciudad de Salta. Monseñor Pérez
      celebró misa en el Colegio por el feliz éxito de esta empresa de las
      Hermanas Terciarias Misioneras Franciscanas.
    </p>
    <p>
      1 de abril de 1990: Inició su transmisión radial Aries, FM Stéreo. Su
      director y propietario es el periodista Mario Ernesto Peña.
    </p>
    <p>
      2 de abril de 1905: Nació en Jujuy, Félix Infante. Ha publicado numerosos
      trabajos históricos y literarios. Su obra destacada: “Manuel Eduardo
      Arias, su vida, su drama”. Incluído en Antología de Orán. Colaboró con la
      publicación trimestral Logos que dirigía el poeta salteño Eduardo Ceballos
      y que editaba el Instituto Cultural Andino.
    </p>
    <p>
      2 de abril de 1941:&nbsp;Falleció en La Plata Diógenes Diez Gómez,
      salteño. Obtuvo el título de abogado, ejerció la docencia en Buenos Aires.
      Fue vocal del concejo escolar de la Capital Federal. Fue director de “El
      Orden” y el diario político “La Libertad”. Escribió obras de temas
      jurídicos, destacándose, “Sentencias en lo penal”.
    </p>
    <p>
      3 de abril de 1817:&nbsp;El comandante Dionisio Puch, del ejército salteño
      del coronel Martín Miguel de Güemes, derrotó a los realistas en Bajos de
      Palpalá, provincia de Jujuy.
    </p>
    <p>
      3 de abril de 1821:&nbsp;Hallándose en desacuerdo el gobernador de Salta,
      general Martín Miguel de Güemes, con el gobernador de Tucumán, Bernabé
      Aráoz, por haberle el segundo negado al primero auxilios para proseguir la
      guerra de la independencia y concluir con los restos del ejército realista
      del Alto Perú, y mientras los emigrados salteños en Tucumán, con el
      coronel Manuel Eduardo Arias, que militaba por esos entonces en el
      ejército tucumano, influía en Aráoz, acrecentando su oposición a Güemes
      para lograr la caída política de este produjo la guerra entre ambos
      gobernantes. Güemes hizo invadir la provincia enemiga y en Rincón de
      Marlopa, cerca de la ciudad de Tucumán, el coronel Abraham González
      (tucumano), derrotó a las fuerzas aliadas de Santiago del Estero y Salta,
      mandadas por los coroneles Felipe Ibarra y Alejandro Heredia. En el Rincón
      de Marpola, provincia de Tucumán, se produjo el encuentro armado entre las
      tropas de Ibarra, con el apoyo de Alejandro Heredia y las huestes de
      Aráoz, al mando de Abraham González. La victoria favoreció al Ejército de
      Aráoz. Se produjo la retirada santiagueña. Ibarra y sus tropas acamparon
      en Vinará, cerca del Río Hondo: desde allí comenzó a gestarse el histórico
      tratado interprovincial.
    </p>
    <p>
      3 de abril de 1822: Murió Marís del Carmen Puch de Güemes, meses después
      de la muerte de su esposo, el general Martín Miguel de Güemes. Su muerte
      ocurrió en Rosario de la Frontera, provincia de Salta.
    </p>
    <p>
      3 de abril de 1936: Nació en Capital Federal, Jorge Armando Dragone. Se
      recibió de médico en Córdoba en 1960. Se especializó en Neurología en 1965
      y en Rehabilitación Médica en 1969. Se casó con la profesora Cristina
      Sipowicz con quien tiene 8 hijos. Fue Jefe de trabajos prácticos de la
      cátedra de Patología Médica en la Universidad Nacional de Córdoba. Jefe de
      trabajos prácticos de la cátedra de Clínica Neurológica. Jefe de Fisiatría
      y luego Director Técnico de ALPI en Salta. Jefe del Servicio de Neurología
      y Electroneurofisiología del Hospital Cristofredo Jakob (1971 – 1980).
      Jefe de Servicio de Neurología y Electroneurofisiología del Hospital San
      Bernardo (1973 – 1979). Jefe del programa de Rehabilitacion del Ministerio
      de Salud Pública de Salta (1990 – 1991). Miembro de la Comisión Directiva
      de la Sociedad Neurológica Argentina. Profesor de Neuroanatomía y
      Neurofisiología en la carrera de Psicología de la Universidad Católica de
      Salta (1971 – 1974). Ejerce la Neurología y la Electroneurologia en su
      consultorio privado en la ciudad de Salta. Miembro de la Sociedad
      Argentina de Electroencefalografía y Neurofisiologia Clínica. Miembro del
      Colegio Argentino de Neurólogos Clínicos. Publicó, “Apreciación cristiana
      de la Reflexiología”, Psicoanálisis, Reflexiología y Conversión Cristiana”
      (1965), “Disfunción Cerebral y poliomielitis” (1973), “La hemiatrofia
      facial progresiva” (1985), “Sindrome de Vogt – Koyanagi – Harada” (1982),
      “la inmadurez visomotora” (1990), “Crisis sensitivo – motora de origen
      medular” (1991). Fue becario por ALPI. Es también hombre de letras, que
      figura en varios libros con sus aportes, logró premios en Salta y en
      Mendoza. Colabora en publicaciones culturales.
    </p>
    <p>
      3 de abril de 1956: Murió en Buenos Aires, Carlos Ibarguren, escritor
      salteño, figura de relieve de las letras argentina. Nació en Salta el 18
      de abril de 1877. Su último libro lo tituló: “La historia que he vivido”.
    </p>
    <p>
      3 de abril de 1985: A los 86 años, falleció en Salta el Dr. Atilio
      Cornejo, el más calificado de los historiadoers salteños de la actualidad
      y uno de los más elevados exponentes de la investigación histórica en
      América Hispana. El Dr. Cornejo era miembro de número de la Academia
      Nacional de la Historia del Instituto de Investigaciones de Historia del
      Derecho Argentino y Americano, del Instituto San Felipe y Santiago de
      Estudios Históricos de Salta y de la Academia del Instituto Güemesiano de
      Salta.
    </p>
    <p>
      4 de abril de 1817: El capitán Francisco Gorriti, del ejército de gauchos
      del coronel Martín Miguel de Güemes, derrotó a los realistas, en La
      Capilla, provincia de Salta.
    </p>
    <p>
      4 de abril de 1931: Se creó en la Parroquia de San José, en Metán, una
      filial de la Acción Católica Argentina.
    </p>
    <p>
      5 de abril de 1868: Se produjo el fallecimiento del Vicario general del
      Obispado de Salta, don Isidoro Fernández.
    </p>
    <p>
      5 de abril de 1917: Nació en Salta, Roberto Albeza, Profesor de Castellano
      y literatura. Poeta de alta expresión. Publicó: Imágenes para recordar
      (1955); Romances del Callejón (1956); De los aledaños (1957); Arbol solo
      (1959); Romances de dos ramales (1960); Imágenes encendidas (1986). Fundó
      y dirigió junto a Jorge Hugo Román y Esdras Gianella, la revista cultural
      Pircas.
    </p>
    <p>
      5 de abril de 1954:&nbsp;Nació en La Plata, provincia de Buenos Aires,
      Agustín Ricardo Martínez. Entre 1979 y 1986 fue médico de Cachi. De 1986 a
      1988 fue jefe del programa provincial de atención primaria de la salud.
      Entre 1988 y 1989 fue secretario de coordinación operativa del ministerio
      de Salud Pública y entre 1989 y 1991 integrante de la Comisión de Reforma
      del Estado. En 1993 fue electo senador provincial suplente por Cachi.
      Desde 1993 a 1995, asesor del senador Walter Wayar. El gobierno del Dr.
      Juan Carlos Romero, lo designó Ministro de Salud Pública de la Provincia
      de Salta, cargo que ejerce actualmente.
    </p>
    <p>
      6 de abril de 1811:&nbsp;En la madrugada de este día se abrieron para
      levantar el monumento conocido don el nombre de Pirámide de Mayo, colocada
      en la Plaza Victoria, a treinta metros del Cabildo, a los 34º 36′ 24” de
      latitud sur, posición que tomó su constructor el señor Mossetti, desde el
      observatorio astronómico, que tenía en el Convento de Santo Domingo.
    </p>
    <p>
      7 de abril de 1840: El gobierno de la provincia de Tucumán, encabezando la
      Liga del Norte, se pronunció contra el gobernador de Buenos Aires, Juan
      Manuel de Rosas, y el 18 de ese mes el gobierno de Jujuy adhirió a la
      misma, y posteriormente La Rioja, Catamarca y Salta. Esta Liga del Norte
      fue deshecha en las batallas de Quebracho Herrado y Famaillá.
    </p>
    <p>
      7 de abril de 1896: Nació en Salta, Ernesto Cornejo Saravia. Se graduó de
      médico en 1920. Murió el 21 de enero de 1953.
    </p>
    <p>
      8 de abril de 1817: El capitán Mariano garcia, perteneciente al ejército
      salteño del coronel Martin Miguel de Guemes, derrotó a los realistas, en
      Cangrejillos, provincia de Jujuy.
    </p>
    <p>
      8 de abril de 1987:&nbsp;Llegó de visita a Salta, su santidad Juan Pablo
      II, quien aterrizó a última hora de la tarde. Del aeropuerto se dirigió al
      Hipódromo, donde lo esperaba el creyente pueblo salteño. Pernoctó en el
      Palacio Arzobispal, frente a la plaza 9 de julio.
    </p>
    <p>
      9 de abril de 1819: Murió en Buenos Aires, a los 37 años de edad Mariano
      Boedo. Había nacido en Salta, capital el 25 de julio de 1782, hijo de don
      Manuel Antonio Boedo y de doña María Magdalena Aguirre. Estudió las
      primeras letras en Salta, fue llevado a Córdoba, donde ingresó en el
      seminario de Nuestra Señora de Loreto. Luego pasó a Charcas (hoy
      Sucre-Bolivia) donde terminó su carrera de derecho en 1805. Ejerció su
      profesión en Salta y se adhirió al movimiento de Mayo de 1810. Cuando se
      constituyó el Congreso de Tucumán, Güemes nombró a los diputados
      representantes de la provincia, al doctor Boedo, al coronel Moldes y al
      doctor Ignacio Gorriti, el 11 de diciembre de 1815. Fue nombrado
      vicepresidente del Congreso. Ilustre hombre de la Independencia, nacido en
      Salta.
    </p>
    <p>
      9 de abril de 1929: Nació en Córdoba, Neri Cambronero. En 1957 egresó de
      la Escuela de Bellas Artes “Dr. Figueroa Alcorta” de Córdoba, con el
      título de profesor de Artes Plásticas. Se radicó en Salta en 1958. Docente
      en la Escuela Provincial de Bellas Artes de Córdoba y en Salta. Realizó
      innumerables muestras y logró importanes premios. En 1989 recibió la
      distinción al Merito Artístico, instituido por la provincia de Salta.
    </p>
    <p>
      9 de abril de 1940: Nació en Salta, David Marcelo Pérez. Músico y
      compositor de temas del cancionero latinoamericano. Es un estudioso de las
      culturas precolombinas.
    </p>
    <p>
      9 de abril de 1949: Nació en Salta, Carlos Yarade. Contador Público
      Nacional, que está dedicado a su profesión. Empresario en medios de
      difusión. Como político resulto electo Concejal de la Municipalidad de la
      Ciudad de Salta, ocupando la presidencia del cuerpo.
    </p>
    <p>
      9 de abril de 1992: Se inauguró la nueva sede del Sindicato Gastronómico
      en la ciudad de Salta, el la calle Balcarce esquina Entre Rios. contando
      con oficinas, consultorios y salones. Este terreno fue cangeado en el año
      1952, entre la propiedad que hoy possé el Hogar de Ciegos Corina Lona,
      siendo el secretario general don Faustino Cano y gobernador de Salta don
      Ricardo Joaquín Durand. Cuando se inauguró la nueva sede era secretario
      general de este sindicato, don Gino Durán.
    </p>
    <p>
      10 de abril de 1849: Nació en Salta, Benjamín Figueroa. Se graduó como
      abogado en 1870, luego se doctoró en Jurisprudencia. Fue docente en el
      Colegio Nacional de Concepción del Uruguay y después en el Colegio
      Nacional de Salta, donde llegó a ser rector. Fue diplomático en Perú ,
      Bolivia. Fue Juez Federal en Salta y Jujuy. Fue miembro de la comisión
      reformadora de la Constitución de Salta en 1882. Dos años después
      integraba la comición reformadora de la Constitución Nacional en 1890. Fue
      senador Nacional por Salta en dos oportunidades. El presidente Roca lo
      designó interventor federal de La Rioja, Santiago del Estero y Catamarca.
      Concluyó su carrera pública como magistrado de los tribunales de la
      Capital Federal, donde murió el 15 de noviembre de 1905.
    </p>
    <p>
      10 de abril de 1905:&nbsp;Murió en Salta, Eveida Delgadillo Gurruchaga.
      Dedicó su tiempo a las letras y a la realización de obras benéficas. Su
      casa era el centro del mundo literarios de su tiempo. De su producción
      sólo se conoce un folleto publicado en 1898, titulado: “Francisco de
      Gurruchaga – Justicia Póstuma”.
    </p>
    <p>
      10 de abril de 1930: Falleció en Salta, el doctor Patricio Fleming. En la
      historia de la pediatría ocupa un lugar prominente. Nació en Salta en
      1862. Fue unos de los gestores de la fundación del Hospital de Niños de
      Buenos Aires.
    </p>
    <p>
      10 de abril de 1938: Nació en Salta, Gonzalo Miguel Barbarán. Fue
      presidente del Centro de Estudiantes universitarios de Córdoba. Médico
      cirujano que se desempeñó como Secretario de Estado de Salud Pública. Fue
      presidente del CILAM.
    </p>
    <p>
      10 de abril de 1961: El Sumo Pontifice Juan XXIII erigió la diócesis de la
      Nueva Orán, en la provincia de Salta.
    </p>
    <p>
      10 de abril de 1994: Se llevaron a cabo las elecciones para elegir
      convecionales constituyentes, para la forma de la Constitución Nacioal.
    </p>
    <p>
      11 de abril de 1816: Falleció inesperadamente Monseñor Moxó y de Francolí,
      siendo sepultado en la Catedral de Salta, donde permaneció hasta el año
      1870, cuando el Arzobispo de Charcas, Monseñor Pedro Puch, lo retiró para
      conducirlo a Sucre, donde descansa en la actualidad, en la cripta de la
      Iglesia de San Felipe Neri.
    </p>
    <p>
      11 de abril de 1910: El gobierno nacional creó la Escuela Normal de
      Rosario de la Frontera, en la provincia de Salta.
    </p>
    <p>
      11 de abril de 1931: Se fundó la Liga metanense de Fútbol. Su primer
      presidente fue el doctor Carlos Gutiérrez y la integraban los clubes
      Libertad, Central Norte, Talleres y San José.&nbsp;
      <br />
      11 de abril de 1964: Llegó a Salta, monseñor Carlos Mariano Pérez, para
      hacerse cargo de la Arquidiócesis, siendo aclamado por su grey.
    </p>
    <p>
      12 de abril de 1815&nbsp;: El coronel Martin Miguel de Guemes, derrotó a
      los realistas en Puesto del Marqués de Yavi, provincia de Jujuy.
    </p>
    <p>
      12 de abril de 1931: Se inauguró el servicio telefonico, con 1.100
      abonados en la Ciudad de Salta.
    </p>
    <p>
      12 de abril de 1942: Nació Antonio Zuleta, quien fue intendente de
      Payogasta entre 1985 y 1986. Como deportista ascendió 7 veces el Nevado de
      Cachi y fue el primer andinista que ascendió a todas las cumbres menores,
      que son 9 en total y posee el record de permanencia en el Nevado de Cachi
      con 3 días y 3 noches. Fue secretario de gobierno de Cachi de Walter Wayar
      y de Gustavo Vera Alvarado y administrador de la Finca Palermo, por la que
      tanto trabajó.
    </p>
    <p>
      13 de abril de 1817: El comandante José Francisco Gorriti (a)
      Pachigorriti, del ejército del coronel Martín Miguel de Güemes, derroto a
      los realistas al mando del general de la Serna, en Salta.
    </p>
    <p>
      13 de abril de 1867: Falleció en Salta Manuel Solá Martinez de Tineo.
      Ayudó con su dinero, para que Alberdi pudiera publicar su libro: “Bases
      para la Organización política de la República Argentina”. También colaboró
      con la edición de las obras: “Elementos del Derecho Público Provincial
      para la República Argentina”. Fue elegido el gobernador de Salta.
    </p>
    <p>
      13 de abril de 1914:&nbsp;Se inauguró en Metán, la sucursal del Banco de
      la Nación Argentina. La primera sede estaba en San Martín esquina
      Arenales, luego se trasladó a la calle 9 de julio Nº 56, hasta que ocupó
      su actual edificio propio, uno de sus primeros gerentes fue don Manuel
      Dondi, quien además fue el primer propietario del primer automóvil que
      llego a Metán, un Ford T.
    </p>
    <p>
      14 de abril de 1582:&nbsp;Hernando de Lerma, eligió sitio en el Valle de
      Salta y señaló terminos y jurisdicción, preparando la fundación de la
      ciudad.
    </p>
    <p>
      14 de abril de 1815: Güemes y sus gauchos sorprendieron a las avanzadas de
      la Pezuela al mando del teniente coronel Antonio Vigil en el Puesto Grande
      del Marqués, a las que batieron completamente. Vigil comandaba una
      caballería escogida compuesta de 300 hombres, la mayoría de los cuales
      fueron muertos o tomados prisioneros.
    </p>
    <p>
      14 de abril de 1836: Nació en San Carlos, provincia de Salta, Anastacio
      Basilio F. de Córdoba. Publicó un libro titulado: “Apuntes sobre San
      Carlos en tiempos de unitarios y federales”. Fundó una biblioteca en su
      pueblo natal.
    </p>
    <p>
      14 de abril de 1864:&nbsp;Nació en Salta, Daniel J. Frías. Abogado y
      doctor en Jurisprudencia. Actuó en Salta y Buenos Aires. Fue interventor
      general en Córdoba en 1917 y en 1918 en La Rioja. Falleció en Buenos
      Aires, el 13 de noviembre de 1936.
    </p>
    <p>
      14 de abril de 1895:&nbsp;Se inauguró el Hospital Nuestro Señor del
      Milagro en la ciudad de Salta. Pasó a manos del gobierno en 1950, al igual
      que el Hospital de Niños Francisco Uriburu del año 1913 y la Maternidad
      Luisa B. de Villar del 14 de setiembre de 1924. Todos pertenecieron a la
      sociedad de beneficencia, que estaban presididas por Carmen Anzoátegui de
      Aráoz y Clara Uriburu de Dávalos.
    </p>
    <p>
      14 de abril de 1943:&nbsp;Nació Osvaldo Camisar. Abogado y profesor
      universitario. Fue Diputado Nacional y Vicepresidente del Banco de la
      Nación Argentina, Ministro de Gobierno de Salta, y Presidente del Colegio
      de Abogados de la Provincia de Salta.
    </p>
    <p>
      14 de abril de 1946: Nació en Joaquín V. González, de la provincia de
      Salta, Héctor Rodolfo Díaz Salem. Dirigente y empresario importante de
      Anta. Comenzó su carrera empresrial como transportista de cargas pesadas.
      Es productor de carbón vegetal. Mayorista de productos alimenticios.
      Dirigente de clubes y entidades de Joaquín V. González y permanente
      colaborador del Festival del Quebracho.
    </p>
    <p>
      14 de abril de 1947: Nació en Salta, Víctor Hugo Escandell. Maestro normal
      nacional y profesor en Educación Especial. Poeta que inició su labor
      literaria en el año 1.969. Ha publicado en diarios del noroeste argentino
      y del país. Mereció premios y menciones. Fue director de escuela. Publicó
      el libro “Restos de Naufragio”, editado por la Municipalidad de Campo
      Santo”.
    </p>
    <p>
      15 de abril de 1817: El general José de Serna, al frente del ejército del
      Alto Perú asaltó y ocupó la ciudad de Salta, hasta el 4 de mayo de 1817,
      en cuya fecha se retira acosado por las fuerzas de Martín Miguel de
      Güemes.
    </p>
    <p>
      15 de abril de 1865: Nació en Bolivia, Inés Pavia Valverde, quien en mayo
      de 1993, con sus 128 años, vivía en Villa Lavalle de la ciudad de Salta.
    </p>
    <p>
      15 de abril de 1937: Nació en Salta, Andrés Mendieta, periodista e
      historiador que es Miembro titular del Instituto San Felipe y Santiago de
      Estudios Históricos de Salta, del Instituto Güemesiano, del Instituto
      Browniano. Miembro de honor del Círculo Patriótico Argentino y del Círculo
      Patriótico de Montevideo, Uruguay. Fue jefe de prensa de L.V. 9, Radio
      Salta.
    </p>
    <p>
      15 de abril de 1938: Nació en Salta, Walter Luis Luna. Se graduó en la
      Universidad Nacional de La Plata con el título de ingeniero en
      construcciones. Docente en colegios secundarios y en la Universidad
      Católica de Salta. Se desempeña en la actividad privada y ocupó
      importantes cargos públicos. Actuó en varias provincias argentinas y en
      Bolivia. Fue secretario de Obras Públicas de la Municipalidad de la Ciudad
      de Salta; Presidente del Instituto Provincial de Desarrollo Urbano y
      Vivienda, Administrador General de Aguas de Salta; Interventor de
      Dirección Provincial de Energía; Interventor y Director de la Dirección
      Provincial de Hidráulica; Intendente Municipal. En la actividad política
      fue congresal del partido Justicialista; Elector nacional de presidente y
      vicepresidente de la Nación en los comicios de mayo de 1989; Ex candidato
      a gobernador de la provincia; Diputado provincial por el distrito Salta
      Capital; Asesor de la comisión de recursos naturales y conservación del
      ambiente humano de la Honorable Cámara de Diputados de la Nación.
    </p>
    <p>
      15 de abril de 1942: Nació en Salta, Mariano San Millán. Licenciado en
      administración de empresas, que fue electo diputado por el departamento de
      Guachipas para los períodos 1987/1991 y 1995/1999. Fue convencional
      constituyente en el año 1986 y presidente de la Comisión de Educación de
      la Cámara de Diputados en el período 1995/1997.
    </p>
    <p>
      16 de abril de 1582: Se fundó la ciudad de Lerma en el Valle de Salta,
      situándola a orillas del río de los Sauces (hoy calle San Martín) y el de
      Siancas (Río Vaqueros). Con el fundador estuvieron presentes entre otras
      personas el Obispo Fray Francisco de Victoria, quienes firmaron ante el
      escribano Rodrigo Pereira.
    </p>
    <p>
      16 de abril de 1812:&nbsp;El general doctor Manuel Belgrano, intimó al
      Obispo de Salta a que salga de su territorio en el término de 24 horas,
      por haberlo sorprendido en correspondencia con el jefe realista José
      Manuel Goyeneche.
    </p>
    <p>
      16 de abril de 1914: Nació el doctor Arturo Oñativia. Se doctoró en
      medicina el 2 de diciembre de 1940. Fue Ministro de Asuntos Sociales y
      Salud Pública de Salta en 1955. Fundador y director del Instituto de
      Patología Regional en 1956. Ministro de Salud Pública de la Nación desde
      1963 hasta 1966. Director del Instituto de la Nutrición en 1973. Falleció
      el 1º de abril de 1985.
    </p>
    <p>
      16 de abril de 1948: Nació en El Galpón, provincia de Salta, Cervando José
      Lucena. Caricaturista conocido popularmente con el seudónimo de “Yerba”.
      Se desempeña en diario El Tribuno de Salta. Es el autor del logo de la
      publicación salteña, “La Gauchita”.
    </p>
    <p>
      16 de abril de 1994: Falleció en Cerrillos, provincia de Salta, el
      académico correspondiente en Capital Federal del Instituto Güemesiano de
      Salta, don Paulino Arroyo. Secundó al Doctor Luis Güemes Ramos Mejía y
      luego con uno de sus hijos, el arquitecto Francisco Miguel Güemes Ayerza,
      que fue quien tomó a su cargo la publicación de “Güemes Documentado”.
    </p>
    <p>
      17 de abril de 1582:&nbsp;Hernando de Lerma repartió solares entre los
      nuevos vecinos. Reservó dos para la iglesia mayor, dos junto a estos para
      el Obispo, una cuadra para la plaza, otra para el mismo fundador, una para
      el convento de San Francisco y una para la casa. Otras para el Cabildo,
      para la cárcel.
    </p>
    <p>
      17 de abril de 1920:&nbsp;Se fundó en la ciudad de Salta, el diario El
      Cívico Intransigente, que luego se llamó El Intrasigente.
    </p>
    <p>
      17 de abril de 1956: El artista salteño ventrílocuo Rodolfo Aredes,
      recibió en Puerto Callao, Perú, al Muñeco Pepito, realizado por un
      imaginero peruano. Esta figura lo acompaña desde entonces por todos los
      escenarios que visita. Lograron, juntos, plasmar un dúo de gran calidad
      que deleita a grandes y niños.
    </p>
    <p>
      17 de abril de 1957: Nació en Salta, Mario Vidal Lozano. Artista Plástico
      que logró importantes premios. Es docente en la Escuela de Bellas Artes
      “Tomás Cabrera”.
    </p>
    <p>
      17 de abril de 1990: Se fundó en la ciudad de Salta, el Club de Boxeo Luis
      Angel Firpo. Su primer presidente fue el doctor Miguel Angel Arroyo.
    </p>
    <p>
      18 de abril de 1824:&nbsp;Murió en Buenos Aires, José de Moldes, quien
      había nacido en Salta. Luchó sin retacear esfuerzos por la independencia
      americana. Se inició en la guardia de Corps, la escolta del Rey integrada
      por jóvenes aristócratas y de alcurnia, junto a su hermano Eustaquio, los
      hermanos Gurruchaga y otros. Fundaron una sociedad secreta para promover
      la emancipación de los Pueblos de América. La “Sociedad de los Caballeros
      Racionales”, más conocida como “Logia de Lautaro”. Moldes criticó a
      Belgrano por sus ideas monárquicas, por esto fue apresado y engrillado.
    </p>
    <p>
      18 de abril de 1877: Nació en Salta, Carlos Ibarguren. En 1898 se graduó
      de abogado en Buenos Aires, logrando el premio facultad y medalla de oro,
      como el mejor egresado. Desempeñó importantes funciones a nivel nacional.
      Fue secretario de la Corte Suprema de Justicia, vocal del Consejo Nacional
      de Educación, ministro de Justicia e Instrucción Pública de la Nación,
      interventor federal en Córdoba. Fue también presidente de laComisión
      Nacional de Cultura, presidente de la Academia Argentina de Letras,
      miembro de la Academia Nacional de la Historia, de la Academia de
      Filosofía, de la Academia Argentina de Derecho y Ciencias Sociales.
      Algunas de sus obras: Evolución de las ideas jurídicas en Roma. Una
      proscripción bajo la dictadura de Syla (1908), Obligaciones y contratos en
      el Derecho Romano y la Legislación Argentina (1910), Los impuestos
      confiscatorios (1919), La literatura y la gran guerra (1920), Historia del
      tiempo clásico (1920), Manuelita Rosas (1924), Juan Manuel de Rosas: su
      vida, su tiempo y su drama (1930, premio nacional de literatura). En la
      penumbra de la Historia Argentina (1932), La crisis política del mundo
      (1933), La inquietud de esta hora (1934), Estampas argentinas (1936), Las
      sociedades literarias 1800-1824 (1937), San Martín íntimo (1950), La
      historia que he vivido (1955). Falleció en Buenos Aires el 3 de Abril de
      1956.&nbsp;
      <br />
      18 de abril de 1993: Se inauguró en la plaza Santa Marta de la ciudad de
      San Ramón de la Nueva Orán, el Monumento al Aborigen, construido y donado
      por el Grupo Vocación de Orán. Es el único en su dimensión en toda la
      provincia.
    </p>
    <p>
      20 de abril de 1817:&nbsp;El capitán Benjamín Güemes, del ejército
      patriota, derrotó a los realistas, en El Bañado, provincia de Salta.
    </p>
    <p>
      20 de abril de 1917: Murió en Salta, Delfín Leguizamón. Político,
      legislador; dos veces gobernador de Salta. Actuó contra Felipe Varela y
      estuvo en la guerra del Paraguay. Fue Legislador Nacional.
    </p>
    <p>
      20 de abril de 1934: La Diócesis de Salta fue elevada a Arquidiócesis por
      Bula del Papa Pío XI y ratificada por el Congreso de la Nación. Su primer
      arzobispo fue monseñor Roberto José Tavella.
    </p>
    <p>
      20 de abril de 1994:&nbsp;Falleció Daniel Isa, quien había nacido en Tafí
      Viejo en 1919. Fue presidente del Concejo Deliberante en Colonia Santa
      Rosa e Intendente de esa localidad en el gobierno del doctor Ricardo
      Joaquín Durand. En la década del 80 fue Congresal Provincial del Partido
      justicialista y Senador Provincial.
    </p>
    <p>
      21 de abril de 1817:&nbsp;El comandante Alejandro Burela, perteneciente al
      ejército del coronel Martín Miguel de Güemes, derrotó a los realistas, al
      mando del coronel Vicente Sardina, en Cerrillos, provincia de Salta.
    </p>
    <p>
      21 de abril de 1817:&nbsp;El comandante Rojas, del ejército del general
      Martín Miguel de Güemes, derrotó a los realistas, en El Bañado, provincia
      de Salta.
    </p>
    <p>
      21 de abril de 1817:&nbsp;El capitan Angel de la Zerda, del ejército del
      general Martín Miguel de Güemes, derrotó a los realistas, en Llanos de
      Rosario, provincia de Salta.
    </p>
    <p>
      21 de abril de 1861: Nació en Salta Joaquín Castellanos, hijo de Silvio
      Castellanos y de doña Luisa Burela. Cuando era estudiante secundario,
      participó en un certamen poético, obteniendo premio con su trabajo “El
      Nuevo Edén”. A los 17 años publicó “La leyenda Argentina”, cuyo éxito
      literario lo incorporó al mundo delas letras de ese entonces. Fue
      designado profesor del Colegio Nacional y de la Escuela Normal de
      Catamarca. Se doctoró en 1896 con la tesis: “Cuestiones de Derecho
      Público”. Sobresalió en las letras y en la política. Su obra cumbre, fue
      el largo poema titulado “El Borracho”. Murió en Buenos Aires, el 28 de
      setiembre de 1932.
    </p>
    <p>
      21 de abril de 1896:&nbsp;Se consagraron las campanas del Santuario de
      Catamarca, que mandó fundir el vicario Rafael D’ Amico, por su disposición
      del Obispo diocesano de Salta.
    </p>
    <p>
      21 de abril de 1914:&nbsp;Murió en Buenos Aires, monseñor Matías Linares y
      Sanzatenea. Sus restos fueon traídos a Salta y sepultados en la Iglesia
      Catedral, en medio de la congoja del pueblo salteño, que lloró largamente
      a su pastor, el primero nacido en la provincia.
    </p>
    <p>
      21 de abril de 1961: Nació en San Bernardo de las Zorras, provincia de
      Salta, Sergio Omar Ramos. Ingeniero en Construcciones que fue Concejal en
      Campo Quijano, Subsecretario de Obras Públicas de la Municipalidad de
      Salta, Asesor técnico de los municipios de Cerrillos, San Antonio de los
      Cobre y El Galpón. Delegado del Instituto del Cemento Portland Argentino.
      Es autor del libro “La ciudad de todos – Diagnóstico y propuesta de Obra
      pública de la ciudad de Salta”. En la actualidad es el Intendente de
      Rosario de Lerma.
    </p>
    <p>
      22 de abril de 1817: El capitán Zacarías Yanci, perteneciente al ejército
      del coronel Martín Miguel de Güemes, derrotó a los realistas, en Pulares,
      provincia de Salta.
    </p>
    <p>
      22 de abril de 1817: El comandante Pedro José Zabala, derrotó nuevamente a
      los realistas mandados por el coronel Vicente Sardina, en Cerrillos,
      provincia de Salta.
    </p>
    <p>
      22 de abril de 1928: Nació en Buenos Aires, Osvaldo Aurelio Juane. Se
      radicó en Salta en 1952. Estudió en la Escuela Nacional de Bellas Artes y
      en el departamento de Artes de la Universidad Nacional de Tucumán. Fue
      discípulo de Francisco Pulloc, Cecilia Marcovich, Lino E. Spilimbergo,
      Lucio Fontana, Horacio Juárez y francisco Puyau. Llegado a Salta se
      integró como docente en la Escuela Provincial de Bellas Artes, creada por
      ese tiempo por el artista PajitaGarcía Bes. Como muralista recibió más de
      20 premios, en concursos nacionales, provinciales y privados. Se destacó
      como ilustrador de libros de autores salteños. Fue Coordinador y Asesor de
      Artes Plásticas de la Universidad Nacional de Salta, en su departamento de
      Arte, siendo además, el autor del escudo de esa Universidad. Se desempeñó
      como Director de Cultura de la Municipalidad de la Ciudad de Salta. Fue
      uno de los principales gestores de la elaboración de la Ley Nº 6475 de
      Régimen de Reconocimiento al Mérito Artístico, en la que trabajó hasta
      lograr su sanción. Falleció en Salta en 1988.
    </p>
    <p>
      22 de abril de 1955:&nbsp;Se inauguró el Colegio Nacional y la Escuela
      Comercial Anexa de Metán. Al colegio se lo denominó Juan Carlos Dávalos.
    </p>
    <p>
      22 de abril de 1957:&nbsp;Se fundó el Colegio del Milagro, como escuela Nº
      0977. Su fundadora y primera directora fue María Mercedes Nan Lafuente. Se
      inició con la implementación de jardines de infantes. En la actualidad
      cuenta con el nivel primario, secundario y terciario completos. La actual
      directora es la señora Norma del Valle Corvalán de Arías.
    </p>
    <p>
      22 de abril de 1994: Falleció en Capital Federal, Alejandro Mackluf a los
      74 años. Fue diputado y presidente de la Cámara de Diputados. En Salta fue
      director de la Escuela Superior de Conducción Justicialista de Salta.
    </p>
    <p>
      23 de abril de 1817: El capitán Juan Zambrano, del ejército del coronel
      Martín Miguel de Güemes, derrotó a los realistas en La Silleta, provincia
      de Salta.
    </p>
    <p>
      23 de abril de 1950: Se fundó la Cooperativa Agrícola – Ganadera “Gral.
      San Martín” de Metán. Recibió por Ley Nº 1515, los molinos arroceros que
      la provincia le transfiere.
    </p>
    <p>
      23 de abril de 1994: Se inauguró la Casa de la Cultura de Rosario de
      Lerma, Provincia de Salta.
    </p>
    <p>
      24 de abril de 1869: Cesó en sus funciones el gobernador de Salta, Sixto
      Ovejero. Lo sucedió asumiendo ese mismo día, Delfín Leguizamón.
    </p>
    <p>
      24 de abril de 1882: Fue asesinado por la tribu del cacique Tapé Chico, el
      doctor Julies Crevaux y sus compañeros, mientras realizaba una expedición
      científica sobre el río Pilcomayo.
    </p>
    <p>
      24 de abril de 1931:&nbsp;Nació en Orán, Hugo A. Luna. Docente de
      establecimientos secundarios de Orán. Está incluído en una antologia de
      Orán. Es autor de “Conozcamos lo nuestro”, pequeños libros que conforman
      una colección sobre geografía, historia y cultura de Orán.
    </p>
    <p>
      25 de abril de 1852: Inauguró con un brillante discurso la Legislatura
      Provincial de Salta, el doctor Facundo Zuviría.
    </p>
    <p>
      25 de abril de 1887: Falleció en Salta Miguel Francisco Aráoz (h), quien
      había nacido en 1817 y fue el fundador del ingenio “La Esperanza” en la
      provincia de Jujuy. Fue gobernador de Salta.
    </p>
    <p>
      25 de abril de 1922: Se firmó un convenio en Santiago de Chile dejándose
      establecido que Argentina y Chile resolvían recíprocamente construir dos
      (2) vías férreas que unirían sin trasbordo Antofagasta con Salta; y uno en
      el límite sur para unir Bahía Blanca con Concepción y el Puerto
      Talcahuano.
    </p>
    <p>
      26 de abril de 1834: Nació en Salta Cleto Aguirre. Fue médico, político,
      diputado y gobernador. Murió en Buenos Aires, el 11 de febrero de 1889.
      Fue elegido diputado nacional en 1862 y gobernador en 1864, fue miembro de
      la Academia Nacional de Medicina, y decano de la Facultad de Medicina y
      vocal del Concejo Nacional de Educación.
    </p>
    <p>
      27 de abril de 1821: El general doctor José Ignacio Gorriti, al frente de
      los patriotas salteños, derrotó completamente a las tropas realistas
      mandadas por el coronel Guillermo Marquiegui. Gorriti rodeó y apresó a los
      integrantes de la vanguaradia española, tomando alrededor de 400
      prisioneros. Al conocer lo ocurrido, Olañeta retrocedió hasta su cuartel
      en Tupiza. Este día es llamado el Día Grande de Jujuy.
    </p>
    <p>
      28 de abril de 1959:&nbsp;Nació en Salta, Julio De Vita. Periodista que
      conduce programas de opinión en Canal 11 de Salta. Fue secretario de
      prensa y difusión de la provincia y en la actualidad es el Director de
      Prensa del Consejo Deliberante de la ciudad de Salta.
    </p>
    <p>
      28 de abril de 1960: Nació en Salta, Rolando Guillermo Mazzaglia, quien
      egresó de la Universidad Nacional de Salta como Ingeniero en
      Construcciones en 1990. Fue delegado estudiantil y delegado gramial en
      UPCN. Fue concejal municipal de 1995 a 1997; por el Partido Justicialista.
    </p>
    <p>
      29 de abril de 1772: Nació en Salta, Pedro Antonio Arias. Licenciado en
      teología y maestro en artes, luego en Lima se doctoró en derecho civil.
      Fue ministro de Güemes.
    </p>
    <p>
      29 de abril de 1817:&nbsp;El comandante Bonifacio Ruiz de los Llanos, del
      ejército del coronel Martín Miguel de Güemes, derrotó a los realistas en
      Salta.
    </p>
    <p>
      29 de abril de 1968: Se autorizó por decreto Nº 2227 del gobierno
      nacional, el funcionamiento de la Universidad Católica de Salta, conforme
      a la nueva ley de universidades privadas. La Universidad Católica de
      Salta, auspiciada por el centro cultural del noroeste, tiene como
      documento primero, un decreto del señor Arzobispo de Salta, doctor Roberto
      José Tavella con fecha 19 de marzo de 1963. Obtiene personería jurídica en
      1964 y pudo usar el nombre de Universidad, pero sin aprobación oficial,
      desde 1965. Se cursan gran variedad de carreras.
    </p>
    <p>
      29 de abril de 1969:&nbsp;Nació en General Güemes, provincia de Salta,
      Roque Ramón Salas. Luego del secundario, tomó clase de cerámica en la
      Escuela Municipal y guitarra. En la ciudad de Salta, en los Talleres
      Artísticos Jaime Dávalos estudia instrumentos autóctonos. Trabajó en APADI
      como profesor de cerámica y realizó musicoterapia. Logró la formación de
      varios conjuntos. Participó en el XXX Encuentro Nacional Infantil de
      Folklore, en La Cumbre, provincia de Córdoba, logrando el 3er. premio. En
      1991 se consagró como solista instrumental. En 1995 logró clasificar para
      el Pre-Baradero en el rubro duo instrumental. Participó en festivales de
      Antofagasta (Chile), Serenata a Cafayate, Enero Tilcareño, Cocina
      Regional, Festival de la Zamba, Festival Nacional de la Pachamama en
      Amaicha del Valle. Actualmente es el director de Cultura de la
      Municipalidad de Gral. Güemes.
    </p>
    <p>
      29 de abril de 1992: Falleció el maestro de fútbol Nicolás Vitale. Fue el
      forjador de un importante semillero. Se desempeñó como director técnico de
      varios equipos de fútbol de Salta.
    </p>
    <p>
      30 de abril de 1969:&nbsp;Falleció en Buenos Aires, a los 47 años de edad,
      monseñor Francisco de la Cruz Muguerza, primer Obispo de Orán. Fue
      conducido a su sede y enterrado en la Catedral de Orán.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
